import React, { useState } from "react"
import Button from 'components/button'

import axios from 'axios'
import { ajaxUrl, emailIsValid } from 'utils'
import FadeCollapse from 'components/fadeCollapse'

export default ({ }) => {
    const [inputValue, setInputValue] = useState('')
    const [response, setResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()

        if(!emailIsValid(inputValue)){
            return 
        }

        setIsLoading(true)

        const formData = new FormData()

        formData.append('action', 'subscribe_user')
        formData.append('email', inputValue)

        axios.post(ajaxUrl, formData)
            .then(({ data }) => {
                const {
                    errors
                } = data.response

                setIsLoading(false)

                if(errors && errors.length){
                    setResponse(`You appear to be already signed up.`)
                }else{
                    setResponse(`You've subscribed successfully!`)
                }
            })
    }

    return (
        <div>
            <div className="wrapper">
                <h3>
                    Subscribe for updates &amp; insights.
                </h3>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder={'Email address'}
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                    />
                    <Button
                        label={isLoading ? 'One sec...' : 'Subscribe'}
                        icon={'arrow-right'}
                        onClick={handleSubmit}
                        disabled={!emailIsValid(inputValue) || isLoading}
                    />
                    <FadeCollapse open={!!response}>
                        <i className={'fe fe-check'} />
                        { response }
                    </FadeCollapse>
                </form>
            </div>
        </div>
    )
}