import React, { useEffect, useRef, useState } from "react"
import { isEqual } from 'lodash'

import Quote from 'components/quote'
import TextAndImageLink from 'components/textAndImageLink'
import Stories from 'components/stories'
import TwoColumn from 'components/twoColumn'
import Subscribe from 'components/subscribe'
import Hero from 'components/hero'
import PeopleGrid from 'components/peopleGrid'
import ImageWithTextOverlay from 'components/imageWithTextOverlay'
import TextColumns from 'components/textColumns'
import FileGrid from 'components/fileGrid'
import BlogGrid from 'components/blogGrid'
import SearchForm from 'components/searchForm'
import BlogHero from 'components/blogHero'
import ContactSection from 'components/contactSection'
import ProjectsList from 'components/projectsList'
import PropertiesGrid from 'components/propertiesGrid'
import TextSection from 'components/textSection'
import TextWithImage from 'components/textWithImage'

const components = {
    Quote,
    TextAndImageLink,
    Stories,
    TwoColumn,
    Subscribe,
    Hero,
    PeopleGrid,
    ImageWithTextOverlay,
    TextColumns,
    FileGrid,
    BlogGrid,
    SearchForm,
    BlogHero,
    ContactSection,
    ProjectsList,
    PropertiesGrid,
    TextSection,
    TextWithImage,
}

export default ({ content }) => {
    const componentRefs = useRef([])
    const [visibleIndices, setVisibleIndices] = useState([])

    const visibleIndicesRef = useRef(visibleIndices)

    visibleIndicesRef.current = visibleIndices

    const handleScroll = () => {
        let _visibleIndices = []

        componentRefs.current.forEach((componentRef, index) => {
            const offset = componentRef.getBoundingClientRect().top

            let windowHeight = null
            
            if (typeof window != 'undefined') {
                windowHeight = window.innerHeight
            }

            if(offset < (windowHeight * 0.7)) {
                _visibleIndices.push(index) 
            }
        })

        if(!isEqual(_visibleIndices,visibleIndicesRef.current)) {
            setVisibleIndices(_visibleIndices)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        setTimeout(() => {
            handleScroll()
        },300)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const addComponent = (ref, index) => {
        const _componentRefs =  [...componentRefs.current]

        _componentRefs[index] = ref

        componentRefs.current = _componentRefs
    }

    return (
        <div>
            { content.flexibleContent.map((row, index) => {
                const Component = row.__typename.replace('WPGraphQL_Page_Flexiblecontent_FlexibleContent_','')
                const Tag = components[Component]

                const isScrolledIntoView = visibleIndices && visibleIndices.indexOf(index) !== -1

                let className = `${Component} ${ isScrolledIntoView ? 'scrolled-into-view' : '' }`

                return (
                    <section 
                        className={className} 
                        ref={ref => addComponent(ref, index)} 
                        key={index}
                    >
                        <Tag {...row} />
                    </section>
                )
            }) }
        </div>
    )
}

