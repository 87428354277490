import React from 'react'

import PropertyGridResults from 'components/propertyGridResults'

export default ({
    heroTitle,
    heroBackgroundImage,
    type
}) => {
    return (
        <PropertyGridResults
            heroHeading={heroTitle}
            heroBackground={heroBackgroundImage}
            type={type}
        />
    )
}
