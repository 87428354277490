import React, { useContext } from "react"
import Button from 'components/button'
import { MainContext } from "context/context"
import { useStaticQuery, graphql } from "gatsby"
import StoryPost from "components/storyPost"

export default ({
    
}) => {
	const {
        options: { logos: {
            standard
        }}
    } = useContext(MainContext)

    const data = useStaticQuery(graphql`
        query {
            siteFields {
                posts (
                    first: 2
                ) {
                    nodes {
                        link
                        title
                        excerpt
                        date
                        featuredImage {
                            mediaItemUrl
                        }
                        categories {
                            nodes {
                                name
                                slug
                            }
                        }
                        tags {
                            nodes {
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const { nodes: posts } = data.siteFields.posts

    return (
        <div className="container">
            <h2 className="with-logo">
                Stories
                <img src={standard.sourceUrl} />
            </h2>
            <ul>
                { posts.map((post, index) => {
                    return (
                        <StoryPost
                            post={post}
                            key={index} 
                        />
                    )
                })}
            </ul>
            <div className="button-row">
                <Button 
                    label="View all stories" 
                    url="/stories"
                    icon={'arrow-right'}
                />
            </div>
        </div>
    )
}
