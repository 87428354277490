import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import StoryPost from "components/storyPost"
import Button from 'components/button'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            siteFields {
                categories(first: 1000, after: null) {
                    edges {
                        node {
                            name
                            slug
                        }
                    }
                }
                posts(first: 1000, after: null) {
                    nodes {
                        title
                    }
                    edges {
                        node {
                            link
                            title
                            excerpt
                            date
                            featuredImage {
                                mediaItemUrl
                            }
                            categories {
                                nodes {
                                    name
                                    slug
                                }
                            }
                            tags {
                                nodes {
                                    name
                                    slug
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

	const visibleResultsIncrementer = 10

	const [visibleResults, setVisibleResults] = useState(visibleResultsIncrementer)
	const [showAll, setShowAll] = useState(false)
	const [selectedCategory, setSelectedCategory] = useState('')

	const handleShowMore = _ => {
		setVisibleResults(visibleResults + visibleResultsIncrementer)
	}

	const handleCategoryClick = (slug) => {
        setSelectedCategory(slug)
        if (slug) {
            setShowAll(true)
        } else {
            setShowAll(false)
        }
	}

    const posts = data.siteFields.posts.edges
    const categories = data.siteFields.categories.edges
    const _categories = categories.filter(function(item) {
        return item.node.slug !== 'uncategorised'
    })

	const _posts = showAll ? posts : posts.length > visibleResults ? posts.slice(0, visibleResults) : posts
    const hasMoreResults = posts.length > visibleResults
    
    return (
        <>
            <ul className="Stories-categories">
                <li dangerouslySetInnerHTML={{ __html: 'All' }} onClick={() => handleCategoryClick('')} className={ selectedCategory === '' && 'active' } />
                { _categories.length &&
                    _categories.map((row, index) => (
                        <li key={ index } dangerouslySetInnerHTML={{ __html: row.node.name }} onClick={() => handleCategoryClick(row.node.slug)} className={ row.node.slug === selectedCategory ? 'active' : '' } />
                    ))
                }
            </ul>
            <div className="Stories">
                <div className="container">
                    <ul>
                        { _posts.length &&
                            _posts.map((row, index) => (
                                <StoryPost 
                                    post={row.node}
                                    key={index}
                                    hide={ showAll ? selectedCategory === row.node.categories.nodes[0].slug ? false : true : false }
                                />
                            ))
                        }
                    </ul>
                    { ( hasMoreResults && !showAll ) &&
                        <div className="button-row">
                            <Button
                                onClick={handleShowMore}
                                label={'Show more'}
                                icon={'plus'}
                            />
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
