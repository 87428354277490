import React from "react"
import moment from 'moment'
import Button from 'components/button'

import Link from 'components/link'

export default ({ post, index, hide }) => {
    
    const {
        date,
        featuredImage,
        categories,
        title,
        excerpt,
        link,
    } = post



    const backgroundImage = featuredImage ? featuredImage.mediaItemUrl : ''

    return (
        <li key={index} className={ hide ? 'hide' : '' }>
            <div className="content">
                <Link to={link}>
                    <div className="meta-row">
                        <span className="date">
                            { moment(date).format('DD.MM.YYYY') }
                        </span>
                        { categories &&
                            <span className="category">
                                { categories.nodes[0].name }
                            </span>
                        }
                    </div>
                    <div className="image-container">
                        <div className="image" style={{ backgroundImage: `url(${backgroundImage})` }}>
                        </div>
                        <div className="overlay">
                            <Button label="Read More" onClick={() => {}}/>
                        </div>
                    </div>
                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                    <div className="copy" dangerouslySetInnerHTML={{ __html: excerpt }} />
                    <span className="readmore" dangerouslySetInnerHTML={{ __html: 'Read more' }} />
                </Link>
            </div>
        </li>
    )
}
