import React from "react"
import Button from './button'

export default ({
    columns, 
    backgroundImage,
    backgroundImageAlignment,
    hideBackgroundOnMobile,
}) => {
    return (
        <div 
            className={`TextColumns-cont`} 
        >
            { backgroundImage ?
                <div className={ hideBackgroundOnMobile ? 'bg hideBackgroundOnMobile' : 'bg' } style={{
                    backgroundImage: backgroundImage ? `url(${ backgroundImage.mediaItemUrl })` : null,
                    backgroundPosition: backgroundImageAlignment ? backgroundImageAlignment : ''
                }} />
            : null }
            <div className="container">
                <ul className="grid">
                    {columns.map((column, index) => {
                        return (
                            <li key={index}>
                                <div dangerouslySetInnerHTML={{ __html: column.text || '' }}></div>
                                {column.button &&
                                    <Button 
                                        outline={true}
                                        label={column.button.title}
                                        url={column.button.url}
                                    />
                                }
                                {column.stats &&
                                    <ul>
                                        {column.stats.map((stat, index) => {
                                            return (
                                                <li key={index}>
                                                    { stat.icon ? <img src={ stat.icon.mediaItemUrl } /> : '' }
                                                    <span className={ stat.colour }>
                                                        {stat.stat}
                                                    </span>
                                                    <p>
                                                        {stat.label}
                                                    </p>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}
