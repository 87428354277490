import React, { useState, useEffect } from "react"
import Logo from '../images/logo.svg'

import Button from 'components/button'

export default ({
    files
}) => {
    const [hasMounted, setHasMounted] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        },1000)
    },[])

    return (
        <div className={`container ${ hasMounted ? 'mounted' : '' }`}>
            <ul className="files">
                {files.map((_file,index) => {
                    const {
                        title,
                        description,
                        file
                    } = _file

                    return (
                        <li 
                            key={index}
                            style={{
                                transitionDelay: (index/10)+'s'
                            }}
                        >
                            <div className="column">
                                <div className="logo-container">
                                    <img src={Logo}/>
                                </div>
                            </div>
                            <div className="column">
                                <h3>
                                    { title }
                                </h3>
                                <p>
                                    { description }
                                </p>
                                { file &&
                                    <Button
                                        label={'Download'}
                                        url={file.mediaItemUrl}
                                    />
                                }
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}