import React from "react"
import Slider from 'components/slider'

export default ({
    slides
}) => {

    return (
        <Slider
            className='carousel'
            options={{
                fade: true,
                wrapAround: false,
                prevNextButtons: false,
                // groupCells: 1,
                cellAlign: 'center',
                pageDots: true,
                adaptiveHeight: true,
                autoPlay: 5000
            }}
        >

            {slides.map((item, index) => {
                const {
                    quote,
                    source,
                    image
                } = item

                const backgroundImageUrl = image ? image.mediaItemUrl : null

                return (
                    <div className="quote-container container">
                    { backgroundImageUrl &&
                        <div 
                            className="image" 
                            style={{backgroundImage: "url("+backgroundImageUrl+")"}}
                        />
                    }
                    <div className="content">
                        <h2>
                            &ldquo;{ quote }&rdquo;
                        </h2>
                        <p>
                            {source}
                        </p>
                    </div>
                </div>
                )
            })}
        </Slider>
    )
}