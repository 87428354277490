import React from 'react'

export default function textWithImage({
    title,
    copy,
    bulletPoints,
    rightLeft,
    image,
    marginBottom
}) {
    let side = ''
    if (rightLeft === 'right') {
        side = 
        <div className={`text-with-image ${marginBottom ? 'margin-bottom' : ''}`}>
                <div className={'column'}>
                    <section className='image'>
                        <img src={image.mediaItemUrl} alt={title}/>
                    </section>
                    <section className={'text'}>
                        <div className='h2' dangerouslySetInnerHTML={{__html: title}} />
                        {copy &&
                        <div className='copy' dangerouslySetInnerHTML={{__html: copy}} />
                        }
                        {bulletPoints && 
                            <ul>
                                {bulletPoints && bulletPoints.map((point, index) => {
                                    return (
                                        <li key={index}>
                                            {point.points}
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </section>
                </div>
            </div>

    } else {
        side = 
        <div className={`text-with-image ${marginBottom ? 'margin' : ''} mobile-grid`}>
                <div className={'column'}>
                    <section className={'text'}>
                        <div className='h2' dangerouslySetInnerHTML={{__html: title}} />
                        {copy &&
                        <div className='copy' dangerouslySetInnerHTML={{__html: copy}} />
                        }
                        {bulletPoints && 
                            <ul>
                                {bulletPoints && bulletPoints.map((point, index) => {
                                    return (
                                        <li key={index}>
                                            {point.points}
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </section>
                    <section className={'image'}>
                        <img src={image.mediaItemUrl} alt={title}/>
                    </section>
                </div>
            </div>
    }

    return (
        <div className='container'>
            {side}
        </div>
    )
}
