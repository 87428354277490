import React, { useContext } from "react"

import Button from 'components/button'
import { MainContext } from "context/context"

export default ({
    columns
}) => {

	const {
        openModal
    } = useContext(MainContext)

    const joinOurPrivateMarketModal = () => {
        openModal({
            type: 'joinOurPrivateMarket'
        })
    }

    return (
        <ul>
            {columns.map((column, index) => {
                const {
                    backgroundColour,
                    heading,
                    subheading,
                    copy,
                    includeLogo,
                    image,
                    link,
                    afterText,
                    buttonType
                } = column

                return (
                    <li className={ image ? backgroundColour : backgroundColour + ' noimage' } key={index}>
                        <div>
                            { includeLogo ? <div className="logo"></div> : '' }
                            <h2>
                                { heading }
                            </h2>
                            { image ?
                                <h4>
                                    { subheading }
                                </h4>
                            :
                                <div className="copy">
                                    <p>
                                        { copy }
                                    </p>
                                </div>
                            }
                            { image ? '' :
                                <div className="button-container">
                                    <Button 
                                        url={ buttonType === 'joinOurPrivateMarketModal' ? undefined : link.url }
                                        label={link.title}
                                        onClick={ buttonType === 'joinOurPrivateMarketModal' ? joinOurPrivateMarketModal : undefined }
                                    />
                                </div>
                            }
                            { image ?
                                <div className="content">
                                    <div className="content-img" style={{backgroundImage: "url("+image.mediaItemUrl+")"}} />
                                    <div className="hover">
                                        <div className="copy">
                                            <p>
                                                { copy }
                                            </p>
                                        </div>
                                        <div className="button-container">
                                            <Button 
                                                url={ buttonType === 'joinOurPrivateMarketModal' ? undefined : link.url }
                                                label={link.title}
                                                onClick={ buttonType === 'joinOurPrivateMarketModal' ? joinOurPrivateMarketModal : undefined }
                                            />
                                        </div>
                                    </div>
                                </div>
                            : '' }
                            { afterText ?
                                <div className="afterText">
                                    <h4>{ afterText }</h4>
                                </div>
                            : '' }
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}