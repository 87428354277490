import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexibleContent from "../components/flexibleContent"

export default ({ data }) => {
	const { 
		databaseId,
    title,
    slug,
		excerpt,
		flexibleContent
  } = data.siteFields.page

  const pageSlug = title.toLowerCase().replace(' ', '-')

	return (
		<>
			<SEO title={title} description={excerpt} />
      <div className={`${pageSlug}`}>
			  <FlexibleContent id={databaseId} content={flexibleContent} />
      </div>
    </>
	)
}

export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      page(id: $databaseId, idType: DATABASE_ID) {
        databaseId
        title
        date
        content(format: RENDERED)
        featuredImage {
          altText
          title(format: RENDERED)
          mediaItemUrl
          slug
        }
        flexibleContent {
          flexibleContent {
            __typename
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Quote {
              slides {
                  image {
                    mediaItemUrl
                  }
                  quote
                  source
                }
              }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BlogGrid {
              numberOfPosts
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FileGrid {
              files {
                title
                description
                file {
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Stories {
              posts {
                post {
                  ... on WPGraphQL_Post {
                    title
                    date
                    featuredImage {
                      mediaItemUrl
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                  }
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TwoColumn {
              columns {
                backgroundColour
                heading 
                subheading 
                copy
                includeLogo
                afterText
                buttonType
                image {
                  mediaItemUrl
                }
                link {
                  title
                  url
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextAndImageLink {
              heading
              copy
              image {
                mediaItemUrl 
              }
              hoverImage {
                mediaItemUrl
              }
              imageText
              link
              backgroundImage {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
              heading
              headingColour
              copy
              image {
                mediaItemUrl
              }
              mobileBackgroundImage {
                mediaItemUrl
              }
              backgroundImage {
                mediaItemUrl
              }
              secondaryBackgroundImage {
                mediaItemUrl
              }
              searchForm
              searchFormType
              parallaxOverflowVisible
              homeHero
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_BlogHero {
              heading
              headingColour
              copy
              image {
                mediaItemUrl
              }
              backgroundImage {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_PeopleGrid {
              title
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ImageWithTextOverlay {
              text
              image {
                mediaItemUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextColumns {
              backgroundImage {
                mediaItemUrl
              }
              backgroundImageAlignment
              columns {
                text
                stats {
                  icon {
                    mediaItemUrl
                  }
                  stat
                  label
                  colour
                }
                button {
                  title
                  url
                }
              }
              hideBackgroundOnMobile
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ContactSection {
              title
              copy
              forms
              backgroundImage {
                sourceUrl
              }
              backgroundImage2 {
                sourceUrl
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ProjectsList {
              projects {
                projectId
                customImage {
                  sourceUrl
                }
              }
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_PropertiesGrid {
              type
              heroBackgroundImage {
                sourceUrl
              }
              heroTitle
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextSection {
              copy
            }
            ... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TextWithImage {
              title
              copy
              marginBottom
              bulletPoints {
                points
              }
              rightLeft
              image {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`