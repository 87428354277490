import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { getPropertiesById } from 'utils'
import { GET_PROPERTIES_BY_IDS } from '../graphql/queries'

import PropertyGridItem from 'components/propertyGridItem'

export default ({
    projects
}) => {
    const [getProjects, {
        loading,
        data,
        error
    }] = useLazyQuery(GET_PROPERTIES_BY_IDS)

    useEffect(() => {
        const uniqueProjectIds = []

        projects.forEach(({ projectId }) => {
            if(uniqueProjectIds.indexOf(projectId) === -1){
                uniqueProjectIds.push(projectId)
            }
        })

        getProjects({
            variables: {
                ids: uniqueProjectIds
            }
        })
    }, [])

    return (
        <div className={'container'}>
            <ul className={`projects-list ${ data ? 'visible' : '' }`}>
                { projects.map(({
                    projectId,
                    customImage
                }, index) => {
                    const projectObject = data ? data.listings.find(_projectObject => {
                        return _projectObject.id === projectId
                    }) : null

                    if(!projectObject){
                        return null
                    }

                    const projectData = JSON.parse(projectObject.dataJSON)

                    return (
                        <PropertyGridItem
                            property={projectData}
                            key={index}
                            isProjectsPage={true}
                            customImage={customImage ? customImage.sourceUrl : null}
                        />
                    )
                })}
            </ul>
        </div>
    )
}