import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SUBMIT_FORM } from '../graphql/queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import { MainContext } from "context/context"
import Form from 'components/form'
import { getUrlParam } from 'utils'

export default ({
    title,
    copy,
    forms,
    backgroundImage,
    backgroundImage2,
}) => {
    const [submitForm, { data: submitFormData, loading: submitFormLoading, error: submitFormError }] = useMutation(SUBMIT_FORM)

    const {
        activeForm,
        setContactForm,
        formVisible,
        setFormVisible,
        isSubmitted,
        setSubmitted,
    } = useContext(MainContext)

    const data = useStaticQuery(graphql`
        query {
            siteFields {
                forms {
                    nodes {
                        id
                        title
                        formFields {
                            sections {
                                title
                                rowsHeading
                                rows {
                                    columns {
                                        label
                                        options
                                        type
                                        required
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const { nodes: _forms } = data.siteFields.forms

    const handleSubmit = data => {
        const json = JSON.stringify(data)

        submitForm({
            variables: {
                data: json
            },
        }).then((result) => {
            const success = result.data.submitForm.success

            document.getElementById('form-container').reset()
            
            setSubmitted(true)

            setTimeout(() => {
                setSubmitted(false)
            },5000)

        }).catch((error) => {
            console.log(error)
        })
    }

    const handleChangeActiveForm = formId => {

        setFormVisible(false)
        setContactForm(formId)

        setTimeout(() => {
            setContactForm(formId)
            setFormVisible(true)
        },300)
        setSubmitted(false)
    }

    const getFormData = id => {
        return _forms.find(_form => _form.id === id)
    }

    const formData = getFormData(activeForm)

    useEffect(() => {
        const type = getUrlParam('type')
        
        if (type === 'appraisal') {
            handleChangeActiveForm('Zm9ybTo2MDM=')
        } else {
            handleChangeActiveForm('Zm9ybTo0NDY=')
        }
    }, [])

    const backgroundFormId = 'Zm9ybTo0NDY='

    return (
        <>
            <div className="contact-page">
                <div className="container">
                    <div className="grid">
                        <div className={ activeForm === 'Zm9ybTo0NDY=' ? 'column appraisalBG' : 'column' }>
                            <h1>
                                { title }
                            </h1>
                            { copy &&
                                <div 
                                    className={'copy'} 
                                    dangerouslySetInnerHTML={{ __html: copy }} 
                                />
                            }
                        </div>
                        <div className={`column form-column ${ formVisible ? 'visible' : '' }`}>
                            <div className="tab-row">
                            { (forms && forms.length > 1) ?
                                <ul className="tabs">
                                    { forms.map(formId => {
                                        const _formData = getFormData(formId)

                                        return (
                                            <li 
                                                key={formId}
                                                className={(forms.length > 1 && activeForm == formId) ? 'active' : null}
                                            >
                                                <button onClick={() => {handleChangeActiveForm(formId)}}>
                                                    { _formData.title }
                                                </button>
                                            </li>
                                        )
                                    })}                                    
                                </ul>
                            : null }
                            </div>
                            <div className="form-container-outer">
                                <Form 
                                    key={activeForm} 
                                    handleSubmit={handleSubmit}
                                    form={formData}
                                />
                                { isSubmitted ? <div className="form-container-success" dangerouslySetInnerHTML={{ __html: 'Thanks. Submission successful!' }}  /> : '' }
                            </div>
                        </div>
                    </div>
                    { backgroundImage &&
                        <img src={backgroundImage.sourceUrl} className={ activeForm === 'Zm9ybTo2MDM=' ? 'bg active' : 'bg' } />
                    }
                    { backgroundImage2 &&
                        <img src={backgroundImage2.sourceUrl} className={ activeForm === 'Zm9ybTo0NDY=' ? 'bg active' : 'bg' } />
                    }
                </div>
            </div>
        </>
    )
}
