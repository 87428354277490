import React from "react"

import ParallaxSection from './parallaxSection'

export default ({
    heading, 
    copy, 
    image, 
    hoverImage,
    imageText, 
    link,
    backgroundImage
}) => {
    return (
        <ParallaxSection 
            backgroundUrl={ backgroundImage ? backgroundImage.mediaItemUrl : null }
            speed={0.6}
        >
            <div className="container">
                <div className="text">
                    {heading &&
                        <h4>
                            {heading}
                        </h4>
                    }
                    {copy &&
                        <p>
                            {copy}
                        </p>
                    }
                </div>
                {image &&
                    <a className="link" href={link}>
                        <div style={{backgroundImage: "url("+image.mediaItemUrl+")"}}>
                            { hoverImage &&
                                <img src={hoverImage.mediaItemUrl} className={'hover-image'} />
                            }
                            <h3 dangerouslySetInnerHTML={{ __html: imageText || '' }} />
                        </div>
                    </a>
                }
            </div>
        </ParallaxSection>
    )
}