import React, { useState, useEffect, useContext, useRef } from "react"
import { MainContext } from "context/context"

import ParallaxSection from 'components/parallaxSection'

export default ({
    heading, 
    headingColour, 
    copy, 
    image, 
    backgroundImage,
    darkenBackground = false,
    parallaxOverflowVisible,
}) => {
    const [hasMounted, setHasMounted] = useState(false)
	const {
        addInvertedHeaderSection
    } = useContext(MainContext)
    const elementRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        },1000)
    }, []) 
    
    useEffect(() => {
        if(elementRef && elementRef.current && !parallaxOverflowVisible){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

    const backgroundImageUrl = backgroundImage ? (backgroundImage.mediaItemUrl || backgroundImage.sourceUrl) : ''
    const imageUrl = image ? image.mediaItemUrl : ''

    let className = ''

    if(parallaxOverflowVisible){
        className += ' visible-parallax-overflow'
    }

    if(hasMounted){
        className += ' mounted'
    }
    
    return (
        <ParallaxSection
            backgroundUrl={backgroundImageUrl}
            darken={darkenBackground}
            className={className}
        >
            <div 
                className={`content ${ imageUrl ? 'has-image' : ''}`} 
                style={{
                    backgroundImage: "url("+imageUrl+")"
                }}
                ref={ref => elementRef.current = ref}
            >
                <div 
                    className="text" 
                    style={{
                        color: headingColour
                    }}
                >
                    <h1 dangerouslySetInnerHTML={{ __html: heading}} />
                </div>
            </div>
            { copy &&
                <div className="BlogHero-copy">
                    <p dangerouslySetInnerHTML={{ __html: copy }} />
                </div>
            }
            
        </ParallaxSection>
    )
}