import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Button from 'components/button'

export default ({}) => {
    const data = useStaticQuery(graphql`
        query {
            siteFields {
                people {
                    nodes {
                        title 
                        featuredImage {
                            mediaItemUrl
                        }
                        peopleFields {
                            position
                            phoneNumber
                            mobileNumber
                            emailAddress
                            linkedin
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className="container">
            <div className="heading-row">
                <h3>
                    Our Team
                </h3>
            </div>
            <ul className="grid">
                {data.siteFields.people.nodes.map((person, index) => {
                    const {
                        title,
                        featuredImage,
                        peopleFields: {
                            position,
                            phoneNumber,
                            mobileNumber,
                            emailAddress,
                            linkedin
                        }
                    } = person

                    const names = title.split(' ')

                    return (
                        <li 
                            key={index}
                            style={{
                                transitionDelay: (index/10)+'s'
                            }}
                        >
                            <div className="image-container">
                                {person.featuredImage &&
                                    <div className="image" style={{backgroundImage: "url("+featuredImage.mediaItemUrl+")"}} />
                                }
                                <div className="overlay">
                                    <div className={'bg'} />
                                    <div className="content">
                                        <div className="content-inner">
                                            <div className="name-container">
                                                <div className="name">
                                                    <h4>
                                                    { names[0].toUpperCase()+' '+names[1].toLowerCase() }
                                                    </h4>
                                                </div>
                                            </div>
                                            <p>
                                                { position }
                                            </p>
                                            { phoneNumber &&
                                                <span>
                                                    Ph. { phoneNumber }
                                                </span>
                                            }
                                            { mobileNumber &&
                                                <span>
                                                    M. { mobileNumber }
                                                </span>
                                            }
                                        </div>
                                        { emailAddress || linkedin ?
                                            <div className="button-container">
                                                <Button 
                                                    label={`Email ${ names[0] }`}
                                                    url={`mailto:${ emailAddress }`}
                                                    inverted={true}
                                                    icon={'mail'}
                                                    iconAlign={'left'}
                                                />
                                                { linkedin &&
                                                    <a href={ linkedin } target="_blank" rel="noopener noreferrer" className="linkedin">
                                                        <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                                                    </a>
                                                 }
                                            </div>
                                        : '' }
                                    </div>
                                </div>
                            </div>
                            <div className="name-container">
                                <div className="name">
                                    <h4>
                                       { names[0].toUpperCase()+' '+names[1].toLowerCase() }
                                    </h4>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}